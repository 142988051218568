<template>
  <div style="height: 100%; overflow-y: auto;">
    <div class="d-block d-sm-none">
      <v-btn :ripple="false" class="pl-0" color="white" elevation="0" large @click="$emit('openMenu', true)">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </div>
    <v-container class="componentHeight">
      <v-form ref="form" lazy-validation>
        <div class="px-3">
          <h2>
            ข้อเสนอแนะ
          </h2>
        </div>
        <div class="pt-5 pt-sm-2">
          <v-row class="mx-0 mt-3 mt-sm-0">
            <v-col class="d-flex align-center pb-0 pb-sm-3" cols="auto" md="2" sm="2" style="margin-top: -26px;">
              ที่อยู่อีเมล <span class="red--text">*</span>
            </v-col>
            <v-col cols="" md="5" sm="6">
              <v-text-field v-model="form.email" :rules="[rules.required, rules.email]" dense
                            hint="กรุณาใช้อีเมลจริงที่สามารถใช้งานได้" outlined persistent-hint
                            placeholder="ที่อยู่อีเมล"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-0">
            <v-col class="d-flex align-center pb-0 pb-sm-3" cols="auto" md="2" sm="2">
              ชื่อ <span class="red--text">*</span>
            </v-col>
            <v-col cols="" md="5" sm="6">
              <v-text-field v-model="form.reporter" :rules="[rules.required]" dense hide-details outlined
                            placeholder="ชื่อ"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-0">
            <v-col class="d-flex align-center pb-0 pb-sm-3" cols="12" md="2" sm="2">
              เบอร์โทร <span class="red--text">*</span>
            </v-col>
            <v-col class="pr-0 pr-sm-3" cols="8" md="5" sm="6">
              <v-text-field v-model="form.tel" :rules="[rules.required]" dense hide-details outlined
                            placeholder="เบอร์โทร"></v-text-field>
            </v-col>
            <v-col class="d-flex align-center" cols="1">
              ต่อ
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="form.tel_ext" dense hide-details outlined></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div class="py-5">
          <v-divider></v-divider>
        </div>
        <div>
          <v-row class="mx-0">
            <v-col class="d-flex align-center pb-0 pb-sm-3" cols="auto" md="2" sm="2">
              เรื่อง <span class="red--text">*</span>
            </v-col>
            <v-col cols="" md="5" sm="6">
              <v-text-field v-model="form.title" :rules="[rules.required]" dense hide-details outlined
                            placeholder="เรื่อง"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-0">
            <v-col>
              รายละเอียด <span class="red--text">*</span>
              <v-textarea v-model="form.body" :rules="[rules.required]" class="mt-2" dense hide-details outlined
                          placeholder="รายละเอียด"></v-textarea>
            </v-col>
          </v-row>
          <v-row class="mx-0">
            <v-col class="d-flex align-center pb-0 pb-sm-3 filesizeLabel" cols="auto" sm="2">
              ไฟล์แนบ
            </v-col>
            <v-col cols="" md="5" sm="6">
              <v-file-input ref="fileInput" v-model="form.attachments" append-icon="mdi-paperclip" dense multiple
                            outlined placeholder="แนบไฟล์" prepend-icon=""
                            @click:append="$refs.fileInput.$refs.input.click()"></v-file-input>
            </v-col>
          </v-row>
          <v-row class="mx-0">
            <v-spacer></v-spacer>
            <v-col class="pt-0 pt-sm-3" cols="auto">
              <v-btn :disabled="isLoading" class="mr-2" @click="clearForm()">
                ล้าง
              </v-btn>
              <v-btn :disabled="isLoading" color="primary" @click="sendComment()">
                ส่ง
                <v-progress-circular v-show="isLoading" class="ml-3" indeterminate size="16"></v-progress-circular>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-container>
    <v-dialog v-model="isOpenDialog" max-width="600">
      <v-card>
        <v-card-title>
          <v-spacer/>
          <v-icon @click="isOpenDialog=false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pa-0">
          <div v-show="isQuerySuccess" class="py-9 success--text align-center justify-center text-center"
               style="font-size: 20px;">
            บันทึกสำเร็จ
          </div>
          <div v-show="!isQuerySuccess" class="py-9 warning--text align-center justify-center text-center"
               style="font-size: 20px;">
            บันทึกไม่สำเร็จ
          </div>
        </v-card-text>
        <v-card-actions>
          <div class="d-flex justify-end" style="width: 100%">
            <v-btn elevation="0" @click="isOpenDialog=false">
              ปิด
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import projectConstant from '../../assets/js/projectConstant'
import utils from '../../assets/js/utils'

export default {
  name: 'Report',
  data () {
    return {
      form: {
        reporter: '',
        tel: '',
        email: '',
        title: '',
        body: '',
        tel_ext: '',
        attachments: []
      },
      isLoading: false,
      isOpenDialog: false,
      isQuerySuccess: null,
      rules: projectConstant.rules
    }
  },
  methods: {
    clearForm () {
      this.$refs.form.reset()
    },
    async sendComment () {
      const isValid = this.$refs.form.validate()
      if (isValid) {
        this.isLoading = true
        this.isQuerySuccess = await this.$store.dispatch('helpDesk/sendComment', this.form)
        this.clearForm()
        this.isOpenDialog = true
        this.isLoading = false
      }
    },
    fileSizeRule (files) {
      const sizes = files.map(item => utils.getFileSizeInMb(item))
      const reducerFunction = (accumulator, eachValue) => accumulator + eachValue
      const sumSize = sizes.reduce(reducerFunction, 0)
      if (sumSize > 8) {
        return 'ขนาดไฟล์รวมกันมากกว่า 8Mb'
      } else {
        return true
      }
    }
  }
}
</script>

<style scoped>
::v-deep .row + .row {
  margin-top: 3px;
}

@media (min-width: 600px) {
  .filesizeLabel {
    margin-top: -24px;
  }
}

@media (max-width: 600px) {
  .componentHeight {
    height: calc(100% - 44px);
    overflow-y: auto;
  }
}

/*@media (min-width: 600px) {*/
/*  .componentHeight {*/
/*    height: 100%;*/
/*    overflow-y: auto;*/
/*  }*/
/*}*/
</style>
